/*jshint -W020, -W030 */
/* global d3, _wCenter:true, _tree:true, _webContainer:true, _outersContainer:true, _nodesContainer:true, click */

//////////////////////////////////////////////
//////////////////////////////////////////////
//
//
//
/* Mindmap V1.0 by Labubulle pour Mediaclap */
//
//
//
//////////////////////////////////////////////
//////////////////////////////////////////////

//
// On dessine le svg et on gère le resize et on redessine le mindmap
//


function draw_svg() {
	_w = window.innerWidth;
	_h = window.innerHeight;
	_largestSide = _w > _h ? _w : _h;
	_wCenter = {x : Math.round(_w / 2), y : Math.round(_h / 2)};
	_smallestAxe = _w < _h ? _w : _h;
	_tree = d3.layout.tree().size([_w, _h]);
	_svg.attr("width", _w).attr("height", _h);
	
}


draw_svg();	

function update(source, zoomElement) {
	
	if(!_root) {
		_root = _svg.append("g").attr("class", "root");
		_outersContainer = _root.append("g").attr("class", "outersContainer");
		_webContainer = _root.append("g");
		_nodesContainer = _root.append("g").attr("class", "nodeContainer");
	}

	
	// Compute the new tree layout.
	var nodes = _tree.nodes(_json_data),
		links = _tree.links(nodes);
		
	// La fonction magique qui place les nodes et le dessine
	dispatch(source, nodes, links, zoomElement);		
		
	// Le Zoom
	if(zoomElement) {
				
		//var zoomScale = zoomElement.depth < 2 ? 1.1 : _h / 1000;
		var zoomScale = _w > _h ? _w / 1700 : _h / 1100;
		//if(zoomElement.type === "static" || ( zoomElement.layout === "collection" &&  zoomElement.fold !== false )) return false;
		
		if(zoom) {_svg.call(zoom).call(zoom.event);}
		
		if(zoomElement.parent) {
			
			if(zoomElement.status === "closed") {
				zoomTo(-zoomElement.parent.y * zoomScale,  -zoomElement.parent.x * zoomScale, zoomScale); 
			} 
			else {zoomTo(-zoomElement.y * zoomScale,  -zoomElement.x * zoomScale, zoomScale); }
			
		} 
		else if (!zoomElement.children){ 
			zoomTo(-zoomElement.y * zoomScale,  -zoomElement.x * zoomScale, zoomScale); 
		} 
		else {
			zoomTo(-zoomElement.y * zoomScale,  -zoomElement.x * zoomScale, zoomScale); 
		}
	}

}



//
// Chargement du json
//

var jsonsrc;

// Traitement du json local pour Damien et le CLAP

// Si un formateur est connecté

console.log(sessionStorage);
if(sessionStorage && sessionStorage.getItem("formateur")) {
		
	jsonsrc = "mediaclap-formateur.json";
	
	d3.json(jsonsrc, function (error, json) {
		
		if (error) {
			console.log(error);
		    console.log("Erreur de chargement des données formateur");
		    return;
		}		
		_json_data = json;
		myjson = fireJSON(_json_data);
	});
	
}

// Si il hy a un json du clap
else if(typeof localjson != 'undefined' && localjson!=''){
	_json_data =  JSON.parse(localjson);
		myjson = fireJSON(_json_data);
}

// Sinon on utilise le JSON en ajax
else {
	jsonsrc = "data/mediaclap-preprod.json";
	
	d3.json(jsonsrc, function (error, json) {
		_json_data = json;
		myjson = fireJSON(_json_data);
	});
}



//
// Au resize de la fenetre
//
window.onresize = function(){
	draw_svg();
	update(_json_data);
	setRootZoomLevel();
	$(".popin iframedesactivepardavid, .popin #searchContentWrapper").attr("height", $(".popin").height()); //modifier par david conflit avec les vh 16-9 de mes iframe
	$("#popinSearch #searchContentWrapper").attr("height", $("#popinSearch").height());

};




$(function(){
	
	
	$(".nodeopener").on("click touchstart", function(e){
		resetTree();

		e.preventDefault();
		var el = $(this);
		el.parents(".popin").removeClass("active");
		
		changeUrlParam("id", el.data("nodeid"));

		openNodeByURLid(el.data("nodeid"));
	});
		
	
	$("form.search").on('submit', function(e){
		e.preventDefault();
		launchSearch();
		
	});


	function launchSearch(){
		$(".search .error").html("");
		searchResults = [];
		_json_data.children.forEach(toggleAll);
		update(_json_data, _json_data);			
		
		var searchstr = $("input.search").val();
		
		searchTree(_json_data, searchstr);
	}
	
	$("#popin .bg").on("click touchstart", function(e){
		e.stopPropagation();
		//$("#popin").removeClass("active").find("#content").html("");
		$('.popin').toggleClass('active');
		$('.popin').contents().find('iframe').attr('src','http://multimedia.editions-mediaclap.fr/black.html');
		
	});
	
});

//code de david : 

//fonction fullscreen
			$(function () {
				$('#buttonfullscreen').click(function () {
					screenfull.toggle($('#popin')[0]);
				});
				// set the initial values
				//fullscreenchange();
			});
//fin de code de david
