/*jslint nomen: true*/
//
// Les Variables
//


// Les globaux généraux
/*
var d3,
_svg = d3.select("body").append("svg"),
_w, // Largeur de la fenêtre
_h, // Hauteur de la fenêtre
_wCenter, // centre de la fenête {x: npx, y: npx}
_wMargins = 1, // Taille des marges extérieures en % 
_smallestAxe, // Plus petit axe (hauteur ou largeur, _w ou _h en fonction du ratio
_json_data = [], // Les datas extraites du json
_root, // Le noeud racine du mindmap 
_tree, // L'arbre généré par d3
i = 0,  // L'incrementation de l'update des nodes au clic
_node_size = 150, // Taille par défaut des cercles internes
_node_outer_size = 300; // Taille externe des cercles
*/

var d3, _svg = d3.select("body").append("svg"), _json_data, _w, _h, _largestSide, _wCenter, _wMargins = 1, _smallestAxe, _json_data = [], _root, toToggleList = [], _tree, i = 0, _node_outer_size = 200, currentFlower, idi = 0, minScale = 0.5, maxScale = 1.5, preloadQueue = [], currentNode, submenu_depth = 0, searchResults = [];


// Params d'animation
//var duration = 800, easing = "cubic-in-out";
var duration = 400, easing = "linear";