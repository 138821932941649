/*global _nodesContainer, _webContainer, _outersContainer, click:true, update:true */

	///////////////////////////////////
	//
	// Au click sur les cercles
	//
	//////////////////////////////////
	
	function click(d) {
		
		
		if (d3.event.defaultPrevented) return; // click suppressed
		
		if(d.enabled == 0) return;
		
		
		
		if(d3.event) {
			d3.event.stopPropagation();
			d3.event.preventDefault();
		}
		
		if(!$(".sidebar-left").hasClass("closed")){
			resetMenu();
		}
		
		if(d.type === "static" && !d._children && d.status !== "open") {

			setCurrentNode(d.parent);
			clickLink(d);
			return;
		}
		else if(d.type !== "flower" && d.fold !== true && d.status === "open") {
			
			setCurrentNode(d.parent);	
			delete d.fold;
			closeChildrens(d.parent);
			update(d.parent, d.parent);
			changeUrlParam("id", d.parent.urlID);
			
			return;

		}
		else if(d.fold === true && d.status === "open") {
						
			setCurrentNode(d);	
			delete d.fold;
			closeChildrens(d);
			update(d, d);
			changeUrlParam("id", d.urlID);
			return;

		}

		else if(d.depth === 0) {
			
			resetTree();
			
		}
		else if(d.depth > 0 && d.children !== null && d.fold !== true) {
			setCurrentNode(d);	
			var zoomElement = d;
			toggle(zoomElement);			
			update(d, zoomElement);
			
			
		}
				
	}	
	
	function clickThumbGrp(d){
		
		if (d3.event.defaultPrevented) return; // click suppressed
				
		if(d.status === "open" && d.fold != true) { 
			d3.event.stopPropagation();
			if(d.link) {
				clickLink(d);
			}
			return false; 
		} else {
			click(d);
		}
	}
	
	function hoverNode(d) {
		
		if(d.img) {
			d3.event.stopPropagation();
			var imgUrl = "img/" + d.img.src,
				p = d3.select("#node-"+ d.parent.id),
				thmbgrp = p.select(".thumbgrp"),
				thmbgrpimg = thmbgrp.select("image");
									
			if(d.parent.layout === "tree") {
				if(d.parent.img) {
					
					thmbgrpimg.interrupt().transition();
					
					thmbgrpimg
						.transition()
						.delay(100)
						.attr("href", imgUrl);
						
						
						
				} else {
					
					
					thmbgrp.append("image")
								.attr("width", d.img.w)
								.attr("height", d.img.h)
								.attr("transform", "translate("+ -(d.img.w/2) +","+ -(d.img.h/2) +")")
								.attr("xlink:href", function(d) {return "img/" + d.img.src; });
					
				}
			}
		}
	}
	function hoverOutNode(d) {
		
		if(d.img) {
			d3.event.stopPropagation();
			var imgUrl = "img/" + d.parent.img.src,
				p = d3.select("#node-"+ d.parent.id),
				thmbgrp = p.select(".thumbgrp"),
				thmbgrpimg = thmbgrp.select("image");
				
			
			if(d.parent.layout === "tree" && d.parent.img) {
				

				thmbgrpimg.interrupt().transition();
				
				thmbgrpimg
					.transition()
					.delay(100)
					.attr("href", imgUrl);
			}
		}
	}
	
	function clickSearchResults(d){
		
		console.log(d);
		
		var target = $(this).attr("nodeid"),
			popup = $(this).attr("popup");
			
		if(d && d.link) {
			resetTree();		
			changeUrlParam("id", d.ancestor.urlID);
			openNodeByURLid(d.ancestor.urlID);
			
			
			if(d) {
				setTimeout(function(){
					clickLink(d);
				}, 800);
			
			}
		} else {
			resetTree();		
			changeUrlParam("id", d.urlID);
			openNodeByURLid(d.urlID);
		}
		
		
		
	}
	
	function clickLink(l) {

		
		if(l instanceof d3.selection) {
			d3.event.stopPropagation();
		}
		
		
		if(l.link && l.link.target === "blank") {
			window.open(l.link.url, "out");
		} else {
			$("#popin").addClass("active");
			//$("#popin iframe").attr("height", $("#popin").height()); commenter par david
			$('#intercom-container').css({ zIndex: '99999' });//ajouté par david
			$("#popin iframe").attr("src", l.link.url);
		}
	}



	function returnArrowClick(d){
		
		if (d3.event.defaultPrevented) return; // click suppressed
			
			
			// Si la collection n'est pas reppliée on la ferme
			if (!d.fold) {
				toggle(d);
				
				setCurrentNode(d);	
				delete d.fold;
				d3.selectAll(".thumbgrp").attr("transform", null);
				closeChildrens(d);
				update(d, d);
				changeUrlParam("id", d.urlID);
			}
			
			// Si la coolection est pliée on gère le retour à n-1 des éléments du sous-menu plié
			else {
				var d = currentNode.parent; 
				setCurrentNode(d);	
				delete d.fold;
				closeChildrens(d);
				update(d, d);
				changeUrlParam("id", d.urlID);
				return;
			}
					
	}