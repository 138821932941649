$(function(){
	
window.Intercom("boot", {
  app_id: "rfab2nvs"
});

	//Intercom('showNewMessage', 'Vous voulez plus d\'info ?');

	
	$(".close").click(function(e){
		e.preventDefault();
		$(this).parent().toggleClass( "active" );
	});
		
	
	$(".handle").on("click", function(e){
		e.preventDefault();
		var target = $(this).data("target");
		toggleState(target);
		
		setTimeout(resetMenu, 1000);
		
	});
	
	function toggleState(target) {
		var t = $(target);
		
		t.toggleClass(("closed"));
		t.toggleClass("huhu");
		
		t.siblings(".sidebar, .lbb-header-decouverte").addClass("closed");
	}
	

	function hideAllMenus() {
		$(".sidebar, .lbb-header-decouverte").not(".closed").addClass("closed");
	}
	
	function resetMenu() {
		$(".submenu").removeClass("active").removeClass("out");
	}

	var hideheader = setTimeout(function(){
		$(".lbb-header-decouverte").removeClass("open").addClass("closed");
	}, 2200);
/*	
	var hidemenu = setTimeout(function(){
		$(".sidebar").removeClass("open").addClass("closed");
	}, 2400);
	
	var hidesearch = setTimeout(function(){
		$(".search").removeClass("open").addClass("closed");
	}, 2600);
	
	
	$(".lbb-header-decouverte").on("click", function(){
		clearTimeout(hideheader);
	});
	
	$(".sidebar").on("click", function(){
		clearTimeout(hidemenu);
	});
	
	$(".search").on("click", function(){
		clearTimeout(hidesearch);
	});
*/	
	$("svg").click(function(){
		hideAllMenus();
	});
	
	

});