d3menu = d3.select("#d3menu");
menuSlides = d3.select("#menuslides");
lvl = 1;
function generateSideBar(d, container){
	
	
	if(!container) {
	container = d3menu;
	}
	
	var children;
	
	if(d.children) children = d.children;
	else if(d._children) children = d._children;
		
		
		
	if(d.name !== "mediaclap") {
		
		 var li = container.append("li");
		 
		 if(!children && !d.link && d.name !== "Aucune action") {
		 	 a = li.attr("class", "simpletitle")
		 	 		.append("span")
		 	 		.attr("class", "title")
		 	 		.attr("data-nodeid", d.urlID)
		 	 		.attr("href", "#")
		 	 		.html(d.name.replace(/(<([^>]+)>)/ig,""));
		 } else {
			 
			 if(!children && d.link) {
				 
				 	 a = li.append("a")
				 	 		.attr("href", "#")
				 	 		.html(d.name.replace(/(<([^>]+)>)/ig,""))
				 	 		.on("click", function(){
					 	 		clickLink(d);
				 	 		});
			 } else {
			 	 a = li.append("a")
			 	 		.attr("class", "nodeopener")
			 	 		.attr("data-nodeid", d.urlID)
			 	 		.attr("href", "#")
			 	 		.html(d.name.replace(/(<([^>]+)>)/ig,""));
			 }
			 
		 }
		 	 
		 if(children) {
			 
			 li.attr("class", "hasSubMenu");
			 
			 var arrow = li.append("a")
			 		.attr("class", "slideSubMenu")
			 		.attr("data-nodeid", d.urlID)
			 		.attr("href", "#")
			 		.html(">"),
			 	
			 	sub = menuSlides.append("ul").attr("class", "submenu").attr("id", d.urlID).attr("data-childindex", d.child_index),
			 	
			 	arrowback = sub.append("li").attr("class", "slideBackSubMenu ")
			 		.attr("data-submenu", d.urlID)
			 		.attr("data-parentmenu", d.ancestor ? d.ancestor.urlID : 0)
			 	.append("a")
			 		.attr("href", "#")
			 		.html(d.ancestor.name == "mediaclap" ? "Accueil" : d.ancestor.name.replace(/(<([^>]+)>)/ig,""));
			 		
				titleli = sub.append("li");
				
				titleli.append("h3")
						.attr("class", "sectiontitle")
						.html(d.name.replace(/(<([^>]+)>)/ig,""));

		 }
		 	 
	};
		
		
	if(children) {
		children.forEach(function(d){
			generateSideBar(d, sub);
		});
	}
	
	
}

function resetMenu(){
	
	$(".sidebar-left").addClass("closed");
	setTimeout(function(){
		$("ul.submenu").removeClass("out").removeClass("active");
	}, 1000);
	
}

$(function(){
	
	$("body").on("click", ".hasSubMenu a", function(e){
		e.preventDefault();
		var el = $(this),
			ul = $("ul#" + el.data("nodeid"));
		
		
		ul.addClass("active");
		el.parents("ul.active").removeClass("active").addClass("out");
		
		resetTree();		
		changeUrlParam("id", el.data("nodeid"));
		openNodeByURLid(el.data("nodeid"));
		
	});
	
	$("body").on("click", ".slideBackSubMenu", function(e){
		e.preventDefault();
		var el = $(this),
			ul = $("ul#" + el.data("submenu"));
			
			
		//console.log(el.data("parentmenu"));
		ul.removeClass("active");
		
		$("ul#" + el.data("parentmenu")).removeClass("out").addClass("active");
		
		resetTree();		
		changeUrlParam("id", el.data("parentmenu"));
		openNodeByURLid(el.data("parentmenu"));
		
		
	});
	
	$("body").on("click", "a[data-opennode]", function(e){
		
				

		e.preventDefault();
		
		var node = d3.select(".node").data();
		
		//console.log(node[0]);
		
		click(node[0]);
	});
	
	$("body").on("click", 'a[data-target="#search"]', function(e){
		e.preventDefault();
		
		if(!$(".sidebar-right").hasClass("open")) {
			//$(".search").focus();
		};
	
	
	});
	
	
});

//<li><a class="nodeopener" href="#" data-nodeid="0">Pour <strong>tous</strong></a></li>
//<li><a class="nodeopener" href="#" data-nodeid="70">Pour <strong>les catéchisés</strong></a></li>
