/*jshint -W020, -W030 */
/* global d3, _node_outer_size:true, console, subnav:true, duration, wrap:true, toggle:true */

// Au chargement du json
function fireJSON(json) {
	_json_data.status = "open";
	_json_data.child_index = "root";
	_json_data.zoomfactor = 1;
	_json_data.type = "flower";
	_json_data.children.forEach(function(d, i) {
		d.child_index = i;
		seturlIDs(d);
		toggleAll(d);		
		
	});
	
	
	setAncestors(_json_data);
	
	
	preloadImages(preloadQueue, function() {
		update(_json_data);
		openNodeByURLid();
		setRootZoomLevel();
		$("body").removeClass("loading");
	});
	generateSideBar(_json_data);
	
	return _json_data;
}


function ripple(d) {

	if(d.type !== "flower") { return;}
		
	var p = d.parent;
	if(p) {
		p._r = p.r;
		p.r = p.r ? p.r + _node_outer_size : _node_outer_size;
		ripple(p);
	} else {
		d.r = d.r ? d.r + _node_outer_size : _node_outer_size;
	}
}
function rippleOut(d) {
	
	if(d.type !== "flower" ) { return;}
	
		
	var p = d.parent;
	if(p) {
		p.r = p._r;
		rippleOut(p);
	} else {
		d.r = 250;
	}
}



// Toggle All.
function toggleAll(d) {
	if (d.children) {
		d.children.forEach(toggleAll);
		toggle(d);
	}
}



function closeSiblings(e) {
    if (!e.parent || !e.parent.children) { return; }
    
    e.parent.children.forEach(function(d) {
	    if(d.status === "open" && d.status !== "folded") {
			toggle(d);
			if(d.type === "flower" ) {
				rippleOut(e);	
			}
        } else if (e !== d && d.type === "flower" && d.status === "folded" && d.insubnav === true) {
	        unfoldandclose(d);			
        }
    });
    
}



function closeChildrens(d) {
    if (!d.children) { return; }
    
    d.children.forEach(function(d) {
	    if(d.status !== "closed" && d.status !== "folded") {
			toggle(d);
        } else if (d.type === "flower" && d.status === "folded" && d.insubnav === true) {
			unfoldandclose(d);
        }

    });
    if(d.type === "flower" && d.status === "open") {
		rippleOut(d);
	}
    
}


// Toggle children.
function toggle(d, noripple) {
	// close
	if (d.children) {
		closeChildrens(d);
		d._children = d.children;
		d.children = null; // ...On les ferme et on les stock
		
		
		d.status = "closed"; // et on attribue le status closed
		d._children.forEach(function(d, i) {
			d.child_index = i; // On leur attribue un numéro d'index
		});
		toggleAll(d);
		
		if(d.type === "flower") {
			currentFlower = null;
			d.currentFlower = null;
		}

	}
	
	// open
	else {
		d.children = d._children;
		d._children = null;
		closeSiblings(d);
		
		if(d.children) {
			if(d.type === "flower") {
				ripple(d);
			}
			d.status = "open";
			
			if(d.type === "flower") {
				currentFlower = d;
				d.currentFlower = true;
			}
			
			
			
		}
	}
	
}

// On stocke les ancetres au chargement du json
function setAncestors(d){
	
	
	var childs = d.children ? d.children : d._children;
	
	if(childs){
		
		childs.forEach(function(c){
			
			if(d.layout === "collection") {
				c.parentCollection = d;
			} else if(d.parentCollection) {
				c.parentCollection = d.parentCollection;
			}
			
			c.ancestor = d;
			setAncestors(c);
		});
	}
	//console.log(d);
}


// On toggle tous les parents de dédé
function expandTreeUp(d) {
	if(d.children || d._children) toToggleList.push(d);
	
	if(d.ancestor) {
		expandTreeUp(d.ancestor);
	}
	if(d.depth === 0) {
		toToggleList.reverse();
		toToggleList.forEach(function(d){
			if(d.depth > 0) {
				
				var zoomElement = d;
				toggle(zoomElement);			
				update(d, zoomElement);				
				
				toToggleList = [];
			}
		});
	}
}

function resetTree() {
		var d = _json_data;
		setCurrentNode(d);	
		delete d.fold;
		closeChildrens(d);
		update(d, d);
		
		if(d.depth === 0) {
			changeUrlParam("id", "root");
		};
		
		return;
}

function openNodeByURLid(){
	
	if(getURLParameter("id") === null) return;
	var id = getURLParameter("id");

	if(id !== "undefined") {
		expandTreeById(_json_data, id);
		update(_json_data);
	}
}

function seturlIDs(d){
	
	var childs = null;
	d.CODE ? d.urlID = d.CODE : d.urlID = d.child_index;
	indexLunr.add(d);
	if(d.img) {
		preloadQueue.push(d.img.src);		
	}
	if(d.children) childs = d.children;
	else if(d._children) childs = d._children;
	if(childs) {
		childs.forEach(function(d){
			seturlIDs(d);
		});
	}
}

function preloadImages(images, callback) {
	
    var count = images.length;
    if(count === 0) {
        callback();
    }
    var loaded = 0;
    $(images).each(function() {
        $('<img>').attr('src', "img/" + this).load(function() {
            loaded++;
            if (loaded === count) {
                callback();
            }
        });
    });
};

function setCurrentNode(d) {
	
	if(currentNode && currentNode.currentNode) {
		currentNode.currentNode = false;
	}
	
	currentNode = d;
	d.currentNode = true;

	if(d.depth === 0) {
		var scrollmem = $('html,body').scrollTop();
		changeUrlParam("id", "root");
		$('html,body').scrollTop(scrollmem);
	} else {
		var scrollmem = $('html,body').scrollTop();
		changeUrlParam("id", d.urlID);
		$('html,body').scrollTop(scrollmem);
	}
	
	if(d.type === "flower") {
		currentFlower = d;
	}
	
	
}

function getSubmenuDepth(d) {
	
	if(d.status === "open" && d.children) {
		submenu_depth++;
		d.children.forEach(function(c) {
			getSubmenuDepth(c)
		});
	}
	
}


/////////

// Le générateur de sous-menus en arbre

//////////
function generateTreeMenu(d, _decalage) {
	

	
	var lineHeight = 40,
		globalHeight = (d.parent.children.length - 1) * lineHeight / 2,
		side = _decalage > 0 ? "right" : "left";
			
	d.x = currentFlower.x + (d.child_index * lineHeight) - globalHeight;
	d.y = currentFlower.y + _decalage;
	
	d.side = side;
	
	
}

function setMenuSplit(d){
		
	
	/////
	// On génère l'array du menu pour chaque enfant
	////
	var childs = d.children ? d.children : d._children,
		menu = [],
		submenuIndex = 0,
		menuLength = childs.length,
		menuSplit = menuLength / 2;
			
	childs.forEach(function(d, i){
		var prev = menu[menu.length - 1];
		
		if(prev) {
			
			prev["finish"] = i;
		}
		// Si c'est un titre
		if(!d.children && !d._children && !d.link) {
			menu.push({"name" : d.name, "start" : i, "finish" : i});
		}

	});
	
		
	if(menu.length > 0) {
		
		menu.forEach(function(d){
			
			if(menuSplit > d.start && menuSplit < d.finish) {
				
				menuSplit = d.finish;
				
				if(menuLength - d.start < menuLength - d.finish) {
					menuSplit = d.start;
				}				
			}
		});		
	}
	
	
	d.menuSplit = menuSplit;

}
/*
function setMenuSplit(t) {
    var e = t.children ? t.children : t._children,
        r = [],
        n = 0,
        a = e.length,
        i = a / 2;
    e.forEach(function(t, e) {
        var n = r[r.length - 1];
        n && (n.finish = e), t.children || t._children || t.link || r.push({
            name: t.name,
            start: e,
            finish: e
        })
    }), r.length > 0 && r.forEach(function(t) {
        i > t.start && i < t.finish && (i = t.finish, a - t.start < a - t.finish && (i = t.start))
    }), t.menuSplit = i
}
*/

function generateTreeSplit(d, _decalage, splitNbr) {
	
	var lineHeight = 40,
		globalHeight = ((d.parent.children.length - 1)) * lineHeight / 2,
		side = _decalage > 0 ? "right" : "left";
		
	if(d.child_index < splitNbr) {
		d.x = currentFlower.x + (d.child_index * lineHeight) - (globalHeight / 2);
	}
	else {
		d.x = currentFlower.x + (d.child_index * lineHeight) - (globalHeight + (globalHeight / 2)  + (lineHeight / 2));
	}
	
	
	
	
	
	d.y = currentFlower.y + _decalage;
	
	d.side = side;
	
	
}



/////////

// Utils pour les lignes

//////////

var lineFunction = d3.svg.line()
						.x(function(d) { return d.x; })
						.y(function(d) { return d.y; })
						.interpolate("basis");                 



function wrap(text, width) {

  text.each(function() {
    var text = d3.select(this),
        words = text.text().split(/[ \f\n\r\t\v]+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
      }
    }
    
    
  });
}


function d3TextWrap(text, width, paddingRightLeft, paddingTopBottom) {
	
    paddingRightLeft = paddingRightLeft || 5; //Default padding (5px)
    width = width - (paddingRightLeft * 2); //Take the padding into account

    paddingTopBottom = (paddingTopBottom || 5) - 2; //Default padding (5px), remove 2 pixels because of the borders

    var textAlign = text.attr('text-anchor') || 'left';
    var arrLineCreatedCount = [];
    text.each(function() {
        var text = d3.select(this),
            words = text.text().split(/[ \f\n\r\t\v]+/).reverse(), //Don't cut non-breaking space (\xA0), as well as the Unicode characters \u00A0 \u2028 \u2029)
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, //Ems
            x = text.attr("x"),
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            createdLineCount = 1; //Total line created count

        //Clean the data in case <text> does not define those values
        if (isNaN(dy)) dy = 1; //Default padding (1em)
        var dx;
        if (textAlign === 'middle') { //Offset the text according to the anchor
            dx = width / 2;
        }
        else { //'left' and 'right' //FIXME text-anchor 'right' does not have any effect on tspans, only 'left' and 'middle' -> bug ?
            dx = 0;
        }
        x = ((null === x)?paddingRightLeft:x) + dx; //Default padding (5px)
        y = (null === y)?paddingTopBottom:y; //Default padding (5px)

        var tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");
        //noinspection JSHint
        while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width && line.length > 1) {
                line.pop();
                tspan.text(line.join(" "));
                line = [word];
                tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                ++createdLineCount;
            }
        }

        arrLineCreatedCount.push(createdLineCount); //Store the line count in the array
    });
    return arrLineCreatedCount;
}


// Les fonctions d'angles
function toDegrees (angle) {
  return angle * (180 / Math.PI);
}
function toRadians (angle) {
  return angle * (Math.PI / 180);
}



/////////

// Recup de strings dans l'url

//////////


var QueryString = function () {
  // This function is anonymous, is executed immediately and 
  // the return value is assigned to QueryString!
  var query_string = {};
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
        // If first entry with this name
    if (typeof query_string[pair[0]] === "undefined") {
      query_string[pair[0]] = pair[1];
        // If second entry with this name
    } else if (typeof query_string[pair[0]] === "string") {
      var arr = [ query_string[pair[0]], pair[1] ];
      query_string[pair[0]] = arr;
        // If third or later entry with this name
    } else {
      query_string[pair[0]].push(pair[1]);
    }
  } 
    return query_string;
} ();


/////////

// Insere un param dans l'url

//////////


function getURLParameter(name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null;
    }

function changeUrlParam (param, value) {
        var currentURL = window.location.href+'&';
        var change = new RegExp('('+param+')=(.*)&', 'g');
        var newURL = currentURL.replace(change, '$1='+value+'&');

        if (getURLParameter(param) !== null){
            try {
                window.history.replaceState('', '', newURL.slice(0, - 1) );
            } catch (e) {
                //console.log(e);
            }
        } else {
            var currURL = window.location.href;
            if (currURL.indexOf("?") !== -1){
                window.history.replaceState('', '', currentURL.slice(0, - 1) + '&' + param + '=' + value);
            } else {
                window.history.replaceState('', '', currentURL.slice(0, - 1) + '?' + param + '=' + value);
            }
        }
    }