
function zoomed() {
	
	_root.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
}



var zoom = d3.behavior.zoom()
    .translate([0, 0])
    .scale(4)
    .scaleExtent([minScale, maxScale])
    .on("zoom", zoomed);


function zoomTo(x, y, customscale) {	
	
	var scale = customscale ? customscale  : 4;
	
	_root.transition()
		.ease(easing)
		.duration(duration)
		.call(zoom
		  .translate([ x + (_w / 2),
		               y + (_h / 2)
		             ])
		  .scale(scale).event
		);
			
}

function setRootZoomLevel(){
	if(getURLParameter("id") !== null && getURLParameter("id") !== "#root" ) return;

	
	var baseScale =  _w / _w;
	
	if(baseScale > maxScale) baseScale = maxScale;
	else if(baseScale < minScale) baseScale = minScale;

	zoomTo(-_json_data.y * baseScale, -_json_data.x * baseScale, baseScale);
	
}

/*
function reset() {
  _svg.transition()
      .duration(750)
      .call(zoom.translate([0, 0]).scale(1).event);
}
*/