$(function() {
	
	
	// Get the form.
	var form = $('#ajax-contact');

	// Get the messages div.
	var formMessages = $('#form-messages');

	// Set up an event listener for the contact form.
	$(form).submit(function(e) {
		// Stop the browser from submitting the form.
		e.preventDefault();

		// Serialize the form data.
		var formData = $(form).serialize();
		
		// Submit the form using AJAX.
		$.ajax({
		    url: "https://formspree.io/contact@editions-mediaclap.fr", 
		    method: "POST",
		    data: formData,
		    dataType: "json"
		})
		.done(function() {
			
			
			
			form.hide();
			
			$(".merci").show();
			
			
		
		})
		.fail(function(data) {
			form.hide();
			
			$(".erreur").show();
		});

	});
	
	
	
	$(".openIntercom").click(function(e){
		e.preventDefault();
		Intercom('show');
		$(".sidebar-bottom").addClass("closed");
	})

});
