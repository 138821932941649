/*jslint white: true, strict: true */
/* global _wCenter */

function distributeFlower(d) {
		
		if(d.depth > 0 && d.status === "open") {
			d.zoomfactor = 1.6;
		} else {
			d.zoomfactor = 1;
		}
	var largest_side = _w > _h ? _w : _h;
		parent = d.parent, // Le parent
		items = d.parent.children.length, // Le nbr de frangins
		index = (d.parent && d.parent.startangle > 5) ? items - d.child_index : d.child_index, // L'index de l'item avec switch en fonction de la position à l'écran (gauche ou droite) *** <<<< !!!A corriger!!! ****
		parentR = (d.parent.r && d.parent.depth !== 0) ? d.parent.r : 300, // Le radius du parent
		_360 = Math.PI * 2,
		_216 = toRadians(230),
		radius = d.parent.parent ? _216 : _360, // L'angle global du cercle _360 - angleasoustraire 
		items = radius === _360 ? items : items - 1, // La postion de l'item sur le cercle
		increment = (radius / items) * index;
			
		// L'angle de l'item calculé en fonction du coté de la flower *** <<<< !!!A corriger!!! ****
		// Si le neoud parent est à gauche
		if(d.parent && d.parent.startangle > 5){
			var angle =  d.parent.startangle ? d.parent.startangle - increment + toRadians(230/2) + toRadians(40) : increment; 
		}
		// Si il est à droite
		else {
			var angle =  d.parent.startangle ? d.parent.startangle - increment + toRadians (230/2) : increment;
		}
		
		
		//angle = 0 + increment, // L'angle de l'item
		margin = _largestSide * 0.5, // La marge si beasoin
		padding = d.children &&  d.children.length >= 4 ? (d.children.length * 3) : 0,
		r = parentR, // calcul de la taille du cercle
		r2 = parentR + margin;
		
		d.r = d.r ? d.r : 300;		
		
		d.x1 = r * Math.cos(angle) + d.parent.x;
		d.y1 = r * Math.sin(angle) + d.parent.y;

		d.x2 = r2 * Math.cos(angle) + d.parent.x;
		d.y2 = r2 * Math.sin(angle) + d.parent.y;
		
		if(d.status === "open" || d.status === "folded") {
			d.x = d.x2;
			d.y = d.y2;
			
						
		} else {
			d.x = d.x1;
			d.y = d.y1;
		}
		

				
		//d.startangle = angle + toRadians(180 + 45 + angle) ;
		d.startangle = angle;		
		
		
		// La flèche de retour
		
		var arrowangle =   d.startangle ; // L'angle de l'item

		d.arrowX = (d.r * Math.cos(arrowangle)) * 1.3 ;
		d.arrowY = (d.r * Math.sin(arrowangle)) * 1.3 ;
		
		//d.arrowX = (d.x - d.parent.x) / 2 ;
		//d.arrowY = (d.y - d.parent.y) / 2 ;
		
		
}

function distributeStatic(d) {
	
	
	if(d.depth > 0 && d.status === "open") {
		d.zoomfactor = 1.6;
	} else {
		d.zoomfactor = 1;
	}
	
	var p = d.parent ? d.parent : d;
	
	d.x = currentFlower.x;
	d.y = currentFlower.y;
			
	if(p.layout === "collection") {
		generateTreeMenu(d, 190, d.parent.children);
	}
	
	else {
		
		console.log(d.parent.menuSplit);
		//setMenuSplit(d.parent);

		 
		if(d.parent.menuSplit) {
			d.parent.menuSplit = d.parent.menuSplit;
		} else {
			d.parent.menuSplit = d.parent.children.length / 2;
		}
		
		splitNbr = d.parent.menuSplit;
		
		
		
		console.log(d.parent.menuSplit);

		if(d.child_index < splitNbr) {
			generateTreeSplit(d, -230, splitNbr);
		} else {
			generateTreeSplit(d, 230, splitNbr);
		}
		
	}
	
}



function dispatch(source, nodes, links) {
	
	// Placement des éléments
	nodes.forEach(function(d) {
		d.status = d.status ? d.status : "closed";
		
		if(!d.type) {
			if(d.depth <= 2) {
				d.type = "flower";
			} else {
				d.type = "static";
				d.layout = d.layout ? d.layout : "tree";
			}
		}		
		
		// Gestion du niveau root au centre de l'écran
		if(d.depth === 0) {
			d.x = _h / 2;
			d.y = _w / 2;
			d.r = d.r ? d.r : 250;
			d.startangle = toRadians(190);
		} 
		
		// Pour tous les autres
		else if (d.type === "flower") {
			distributeFlower(d);

		} 
		else if (d.type === "static") {
			distributeStatic(d, i);
		}
				
		
	});
	
	drawNodes(source, nodes);
		
	drawLinks(source, links);	  
	drawCircles(source, nodes);	
}