/*jslint white: true, strict: true, getSearchResults */

/*global lunr */



// Gestion du module lunr
var indexLunr = lunr(function () {
    this.field('name', {boost: 10});
    this.field('tags');
    this.field('description');
    this.field('age.text');
    this.ref('urlID');
  
  this.pipeline.add(function (token, tokenIndex, tokens) {
	    return token
	      .replace('à', 'a')
	      .replace('â', 'a')
	      .replace('ä', 'a')
	      .replace('è', 'e')
	      .replace('é', 'e')
	      .replace('É', 'é')
	      .replace('ê', 'e')
	      .replace('ë', 'e')
	      .replace('î', 'i')
	      .replace('ï', 'i')
	      .replace('ô', 'o')
	      .replace('ù', 'u')
	      .replace('û', 'u')
	      .replace('ü', 'u')
	      .replace('ç', 'c');
  }); 
   
    
});
// On déploie le noeud en fonction d'un id
function expandTreeById(d, search) {
	if(d.urlID == search) {
		setCurrentNode(d);
		expandTreeUp(d);
		changeUrlParam("id", d.urlID);
	} else {
		
		var childs = d.children ? d.children : d._children;
		
		if(childs){
			childs.forEach(function(c){
				expandTreeById(c, search);
			});
		}
	}
}


// On recherche dans le json
function searchTree(d, search) {
		
		
		var s = indexLunr.search(search);
		
		if(s.length > 0) {
						
			s.forEach(function(r){
				
				var r = getSearchResults(myjson, r.ref);	
				
			});
			
			formatResults(r);
		}		
		
		// Si il n'y a pas de résultats
		
		
		else {
			$(".search span.error").html("Aucun résultat");
			return;
		}
		
}

// On choppe les résultats texte et on boucle sur les enfants
function getSearchResults(d, id){

	if(d.urlID === id) {
		searchResults.push(d);
	}

	if(d.children) { var children = d.children}
	else if(d._children) { var children = d._children}
	
	if(children) {
		children.forEach(function(c){
			getSearchResults(c, id);
		});
	}
}


// On met en page les réusltats de recherche texte
function formatResults() {
	$("#searchContentWrapper").addClass("active");
	$("#popinSearch #searchContentWrapper").attr("height", $(".popin").height());
	$("#searchContentWrapper h1 span").html($("input.search").val());
	$("#searchContent").html("");
	
	
	//******************** NEW function qui tabasse en masse ******************//

	// On définit un objet à remplir avec les collections et tout leur bouzain
	var orderedResults = {};
	
	
	
	searchResults.reverse();
	
	searchResults.forEach(function(d){
		
		// Si il s'agit d'une collection parente et qu'elle n'existe déjà pas dans notre nouvel objet
		if(d.layout === "collection") {
			
			if(!orderedResults[d.name]) {
				orderedResults[d.name] = d;
				orderedResults[d.name]["childs"] = {};
			}
			
		}
		else {
			
			// Si nous avons une collection parente
			if(d.parentCollection) {
				
				// Si la collection parent n'existe pas déjà
				if(!orderedResults[d.parentCollection.name]){
					orderedResults[d.parentCollection.name] = d.parentCollection;
					orderedResults[d.parentCollection.name]["childs"] = {};
					orderedResults[d.parentCollection.name]["childs"][d.name] = d; 
				} else {
					orderedResults[d.parentCollection.name]["childs"][d.name] = d; 
				}
				
			}	
		}
				
	});
		
	var resultsContainer = d3.select("#searchContent");			
    
    //console.log(orderedResults);
    
	for(var key in orderedResults) {
		var i = orderedResults[key];
		
		// le div conteneur
		var collectionContainer = resultsContainer.append("div");
		
		headerContainer = collectionContainer.append("div").attr("class", "collectionHeader");
		
		// L'image
		headerContainer.append("span")
			.attr("class", "thumb")
			.append("img")
			.attr("src", "img/" + i.img.src);
		
		
		// Le titre
		var h3 = headerContainer.append("h3")
			.append("a")
			.attr("class", "nodeopener")
			.attr("nodeid", i.urlID)
			.html(i.name);

		h3.on("click", clickSearchResults);

			
			
		// La description
		if(i.description) {

			headerContainer.append("p")
				.attr("class", "description")
				.html(i.description);
		}
		
		// Les tags
		if(i.tags) {

			headerContainer.append("p")
				.attr("class", "tags")
				.html("Mots-clé : " + i.tags);
		}
			
		// Les enfants
		if(i.childs && Object.keys(i.childs).length) {
			var kids = i.childs;
			
			
			var childscontainer = collectionContainer.append("div").attr("class", "collectionChilds");
			
			for (var key in kids) {
				var i = kids[key];
				
				// Le titre
				var h4 = childscontainer.append("h4")
					.append("a")
					.data([i])
		 	 		.attr("class", "nodeopener")
		 	 		.attr("href", "#")
					.html(i.name);
				
				
				
					var target = i.link ? i.urlID : i.ancestor.urlID;
					
					h4.attr("popup", i.urlID);
					h4.attr("nodeid", target);
				
					h4.on("click", function(d){
						clickSearchResults(d);
					});
					
			}
				

				
				
				// Les tags
				if(i.tags) {
		
					childscontainer.append("p")
						.attr("class", "tags")
						.html("Mots-clé : " + i.tags);
				}
		}
			
	}
	
	
	//******************** FIN de NEW function qui tabasse en masse ******************//

	
	$("#searchContent").mark($("input.search").val());
}

// La fonction méga crappy qui permet de chopper le nom des ancètres rapido
function getAncestors(d) {
	ancestors = [];
	var names = getAncestorsData(d);
	return names;
	
}
function getAncestorsData(d) {
	
	if(d.ancestor && d.ancestor.name !== "mediaclap") {
		ancestors.push( d.ancestor );
		if(d.ancestor.ancestor) {
			getAncestorsData(d.ancestor);
		}
		return ancestors;
	}
	
}



