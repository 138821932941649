/*jslint white: true, strict: true */
/*global d3, _nodesContainer, _webContainer, _outersContainer, click:true, easing, duration, console */




	///////////////////////////////////
	//
	// Création des $noeuds
	//
	//////////////////////////////////
	
	function drawNodes(source, nodes){
		// Update the nodes…
		var node = _nodesContainer.selectAll("g.node")
			.data(nodes, function(d) { return d.id || (d.id = ++i); });
		
		
		// Enter any new nodes at the parent's previous position.
		var nodeEnter = node.enter().append("g")
			.attr("class", "node niveau-" + source.depth + " " + source.status + " " + source.type + " " + source.layout )
			.attr("id", function(d){return "node-" + d.id;})
			.style("opacity",0)
			.attr("transform", function() { return "translate(" + source.y + "," + source.x + ")"; });
		
		
		///////////////////////////////////
		//
		// $ENTER
		//
		//////////////////////////////////
			
		nodeEnter.each(function(d) {
			
			var node = d3.select(this);
			
			if(d.type !== "flower") {
				drawStaticTitle(d, node);		
			} 
				
			node.append("g").attr("class", "infogrp");
				
			drawThumbgrp(d, node);
			
			
			
			
		});		
		
		

		///////////////////////////////////
		//
		// $UPDATE
		//
		//////////////////////////////////

		// Transition nodes to their new position.
		
		node.each(function(d) {
			var node = d3.select(this);
			
			// On positionne les statics en leur donnant le x de la fleur parente	
			if(d.type !== "flower") {
				if(d.status === "open") {					
					d.x = currentFlower.x;
					d.y = currentFlower.y;
				}
			}
			
			// On détermine si l'élément est fold ou non
			if(d.currentNode !== true && d.children && d.children[0].type !== "flower") {
				d.fold = true;
			}
			else if(d.currentNode === true && d.children && d.children[0].type !== "flower") {
				d.fold = false;
			} else {
				delete d.fold;
			}
			
			if(d.type === "static") {
				d.visibility = "invisible";
			}
			if(d.currentNode || d.fold === true || (d.parent && d.parent.currentNode) ) {
				d.visibility = "visible";
			} 
			
			
			if(d.parent && d.type === "flower" && d.status === "open") {
				var x = d.x,
					y = d.y;
			}
			
			upadateThumbgrp(d, node);
						
			drawDescription(d, node);
			
		});
	
		node
			.attr("class", function(d){ return "node niveau-" + d.depth + " childindex" + d.child_index + " " + d.status + " " + d.type + " " + d.layout + (d.fold ? " folded-" + d.fold : '') + (d.currentNode ? " current-" + d.currentNode : '') + (d.visibility ? " " + d.visibility : '') ;});
	
		node 
			.transition()
			.duration(duration)
			.style("opacity",1)
			.ease(easing)
			.attr("transform", function(d) {
				
				
										
				return "translate(" + d.y  + "," + d.x + ")"; 
			})
			.style("opacity",1);
			
			
			
		
	
		///////////////////////////////////
		//
		// $DELETE
		//
		//////////////////////////////////
	
		
		// Transition exiting nodes to the parent's new position.
		
		node.exit().transition()
			.duration(duration)
			.ease(easing)
			.attr("style", "opacity:0")
			.attr("transform", function(d) { 
				var y = d.parent ? d.parent.y : d.y,
					x = d.parent ? d.parent.x : d.x;

				return "translate(" + y + "," + x + ")"; 
			})
			.remove();
				
	}






	///////////////////////////////////
	//
	// Création des $liens
	//
	//////////////////////////////////
	function drawLinks(source, links){
		// Update the links…
		var link = _webContainer.selectAll("path.link")
			.data(links, function(d) { return d.target.id; });
	
		
		var linkEnter = link.enter().append("path");
		
		
		linkEnter.each(function(d){
			
			var node = d3.select(this);

			if(d.target.type === "flower") {
				var lineData = [ { "y": d.source.x0,   "x": d.source.y0}, { "y": d.source.x	,   "x": d.source.y} ];	
				node.attr("class", "link straight-line id-" + d.target.id)
					.attr("opacity", 0);
			} else {
				var lineData = [ { "y": d.source.x0,   "x": d.source.y0}, { "y": d.source.x	,   "x": d.source.y} ];	
				node.attr("class", "link dotted-line id-" + d.target.id)
					.attr("opacity", 0);			
			}
		});
		link.transition()
			.duration(duration)
			.ease(easing)
			.attr("opacity", 1)
		    .attr("d", function (d){
			    
			    if(d.target.type === "flower") {
				    var lineData = [ { "y": d.source.x,   "x": d.source.y}, { "y": d.target.x	,   "x": d.target.y} ];	
			    }
			    else {
				    var s = d.source,
				    	t = d.target,
				    	lineData = [ { "y": s.x, "x": s.y}, { "y": d.target.x,   "x": d.target.y} ];	
			    }
				
			    return lineFunction(lineData);
			});	
		link.exit().transition()
			.duration(duration)
			.ease(easing)
			.attr("opacity", 0)
			
			.attr("d", function (d){
				var lineData = [ { "y": d.source.x,   "x": d.source.y}, { "y": d.source.x	,   "x": d.source.y} ];	
			    return lineFunction(lineData);
			})
			.remove();
	}	
	
	
	///////////////////////////////////
	//
	// Création des $cercles
	//
	//////////////////////////////////
	
	function drawCircles(source, nodes){
				
		var outerCircle = _outersContainer.selectAll("circle.outer")
			.data(nodes);
		
		outerCircle.enter().append("circle")
			.attr("class", function(d){ return "outer niveau-" + d.depth + " childindex" + d.child_index + " " + d.status + " type-" + d.type;})
			.attr("r", "0")
			.attr("transform", function(d) { return "translate(" + d.y + "," + d.x + ")"; });
		
		outerCircle
			.attr("class", function(d){ return "outer niveau-" + d.depth + " childindex" + d.child_index + " " + d.status;})
			.transition()
			.duration(duration)
			.ease(easing)
			.attr("r", function(d) { return d.r; })
			.attr("transform", function(d) { return "translate(" +  d.y + "," +  d.x + ") scale(1)"; });
	
	
		outerCircle.exit().transition()
			.duration(duration)
			.ease(easing)
			.attr("transform", function(d) { return "translate(" +  d.y + "," +  d.x + ") "; })
			.remove();
	}
	
	///////////////////////////////////
	//
	// Dessines moi des nodes groups
	//
	//////////////////////////////////
	
	// Les Thumbs, ronds, ages
	function drawThumbgrp(d, node) {
		
		var thumbgrp = node.append("g").attr("class", "thumbgrp"),
			returnArrowGrp = node.append("g").attr("class", "returnArrow").attr("data-blublu", d.name),
			txtWrapWidth;
			
			
			thumbgrp
				.on("touchstart", function(){/*d3.event.preventDefault();*/})
				.on("touchmove", function(){/*d3.event.preventDefault();*/})
				.on("click", clickThumbGrp);

	
		// Le titre
		var titre = thumbgrp.append("g")
			.attr("class", "ftitleGroup")		
			.append('foreignObject')
			.attr("y", 0)
			.attr("x", "-95")
			.attr("width", 190)
			.attr("height", 150)
			.append("xhtml:p")
			.attr("class", "foreignTitle")
			.html(d.name);
		
		// L'image
		if(d.img) {
			
			if(d.type === "static") {
				var circle = thumbgrp.append("circle").attr("r", function(d){								
						return d.img.w * d.zoomfactor / 1.4;
					});
			}
			
			var img = thumbgrp.append("image").attr("class", "thumb").attr("xlink:href", function(d) {
			//debut de modif de david pour prendre en compte les image hd des environement
				if (d.img.hd){
				return "img/" + d.img.hd;
				}else{
				return "img/" + d.img.src; }
				//fin de la modification de david
				})
				.attr("width", function(d){								
					return d.img.w * d.zoomfactor;
				})
				.attr("height", function(d){
					return d.img.h * d.zoomfactor;
				})
				.attr("transform", function(d){
					return "translate("+ -((d.img.w * d.zoomfactor) / 2) +","+ -((d.img.h * d.zoomfactor) / 2) +")";
				});
											
				
		}
		else {
			thumbgrp.append("circle")
				.attr("r", 75)
				.attr("opacity", 0);
		}
		
		// L'age
		if(d.age) {
			
			if(d.status === "open" && !d.currentNode) return;
		
			var arc = d3.svg.arc().innerRadius(149).outerRadius(150).startAngle(-Math.PI / 2).endAngle(Math.PI / 2);
			
			thumbgrp.append("path")
			    .attr("d", arc)
			    .attr("class", "agepath")
			    .attr("id", function(d){ return 'agepath-' + d.id;})
			    .attr("fill", "none");
			

			var age = d.age;
			var agetxt = thumbgrp.append("text")
				.attr("class", "age");							
				
			agetxt.append("textPath")
				.attr("xlink:href", function(d){ return '#agepath-' + d.id;})
				.attr("text-anchor", "middle")
				.attr("startOffset","25%")
				.text(function(){
					
					if(age.text) { return age.text; }
					else if(age.min && age.max) { return age.min + " - " + age.max + " ans"; }
					else if(age.min) { return age.min + " ans"; }
				});
		}
		
		

		// la flèche de retour
		returnArrowGrp
				.on("touchstart", function(){/*d3.event.preventDefault();*/})
				.on("touchmove", function(){/*d3.event.preventDefault();*/})
				.on("click", returnArrowClick);

			
		returnArrowGrp.append("circle")
			.attr("r", 25);
			
		returnArrowGrp.append('path')
			.attr("class", "returnarrow")
			.attr('d', function(d) { 
				var w = 15, h = 18;
				return 'M ' + -(w/2 -2) +' '+ -(h/2) + ' l '+ 0 +' '+ h +' l '+ w +' '+ -(h/2) +' z';
			});
			

	}
	
	
	function upadateThumbgrp(d, node) {
		
	
		var thumbgrp = node.select(".thumbgrp"),
			img = thumbgrp.select(".thumb"),
			ftitlegrp = thumbgrp.select(".ftitleGroup foreignObject"),
			titregrp = node.select(".titregrp text"),
			age = node.select("#agepath-" + d.id)
			returnArrowGrp = node.select(".returnArrow"),
			plus = thumbgrp.select(".plus");
			
			
		// La largeur du wrap du texte
		if(d.type === "flower")	{
			txtWrapWidth = 210;
			
			var txt = d.name,
				s = txt.search("<br>") + 4;				
			d.linebreak = s > 3 ? s : null;
							
		}
		else if(d.depth === 1)	{txtWrapWidth = 100;}
		else {txtWrapWidth = 400;}
			
		// La position en x et y du titre
		var ypos = 0,
			xpos = 0;
		
		
		// Pour les flowers				
		if(d.type === "flower") {
			ypos = 80 * d.zoomfactor;
						
			// la flèche de retour
			if(returnArrowGrp && d.arrowX) {

				returnArrowGrp
					.attr("transform", function(d){
						return "translate(" + -(d.arrowY) + "," + -(d.arrowX) +")";
					})
				.select("path")
					.attr("transform", function(d){
						return "rotate(" + -(toDegrees( d.startangle) + 90) +")";
					});				
				
			}
		} 
		// Pour les statics
		else {
			ypos = -110 * d.zoomfactor;
		}
		

		
		
		// L'image	
		img.transition()
			.duration(duration)
			.ease(easing)
			.attr("width", function(d){								
				return d.img.w * d.zoomfactor;
			})
			.attr("height", function(d){
				return d.img.h * d.zoomfactor;
			})
			.attr("transform", function(d){
				return "translate("+ -((d.img.w * d.zoomfactor) / 2) +","+ -((d.img.h * d.zoomfactor) / 2) +")";
			});

		// L'age
		var arc = d3.svg.arc().innerRadius(75 * d.zoomfactor).outerRadius(75 * d.zoomfactor).startAngle(-Math.PI / 2).endAngle(Math.PI / 2);
		age.attr("d", arc);

		// Le titre
		ftitlegrp						
			.attr("y", ypos - 10);


			
			
		// On lance le compteur de niveau sur la flower ouverte pour connaitre le nombre de ses enfants ouverts
		if(d.status === "open" &&  d.currentFlower === true && d.type === "flower" && d.children && d.children[0].type !== "flower") {
			submenu_depth = 1;
			getSubmenuDepth(d);
		}
		// On repositionne tous les éléments concernés par la profondeur du sous menu de la flower ouverte et la flower elle-même
		if(d.children && d.children[0].type !== "flower") {
			
			
			foldScale = (1 / (-d.depth + submenu_depth) / 1.8) ;
			
			if(foldScale != "Infinity") {
				

				yFold = (_h/2)/d.depth + (180 / d.depth);
				
								
					thumbgrp
						.transition()
						.duration(duration)
						.ease(easing)
						.attr("transform", "translate("+ 0 +","+ yFold +")scale("+ foldScale +")");
				
							
			}
			else {
				
					thumbgrp
						.transition()
						.duration(duration)
						.ease(easing)
						.attr("transform", "translate("+ 0 +","+ 0 +")scale("+ 1 +")");
			}

								
		}



			
	}
	
	///////////////////////////////////
	//
	// La description et le bouton de lancement video sa mémé
	//
	//////////////////////////////////
	

		
	function drawDescription(d, node) {
		
		
		// La description
		if(d.description) {
			
			var infogrp = node.select(".infogrp"),
				padding = {top:20, right: 130, bottom: 20, left: 20},
				size = 220,
				rect = infogrp.append("rect").attr("class", "desc-bg"),
				desc = infogrp.append("text")
					.attr("class", "desc")
					.attr("dy", "1em")
					.attr("x", 0)
					.attr("y", 0)
					.text(d.description);
					
					
				desc.call(wrap, size);
				
			var h = desc.node().getBBox().height + padding.top + padding.bottom;
			
			rect.attr("height", h)
				.attr("width", size + padding.left + padding.right)
				.attr("transform", "translate("+ -(size + padding.right + padding.left) +","+ - ((h / 2)) +")");
				
			desc.attr("transform", "translate("+ -(padding.right) +","+ - (h / 2 - padding.top) +")");
			
			d.description.size = {"w" : size, "h" : h};							
		}
		
		
		// Le bouton video de presentation
		if (d.type === "flower" && d.link) {
			
		//if (d.description && d.link) {
			
			var infogrp = node.select(".infogrp"),
				videolinkgrp = infogrp.append("g"),
				videolinkvline = videolinkgrp.append("line"),
				videolinkwline = videolinkgrp.append("line"),
				videolinkrect = videolinkgrp.append("rect"),
				videolinktxt = videolinkgrp.append("text"),
				videolinkicongrp = videolinkgrp.append("g"),
				videolinkiconcircle = videolinkicongrp.append("circle"),
				videolinkiconicon = videolinkicongrp.append("path"),
				descsize = desc ? rect.node().getBBox() : node.select(".thumbgrp").node().getBBox();
				
			var iconSize = 20,
				videolinkgrpMargin = {
					top:  (iconSize / 2) -15,
					right: 20,
					bottom: 0,
					left: size / 2
										
				};
			
			
			// pour les sous-noeuds de collections
			if(d.layout) {
				
				videolinkvline.attr("style","stroke:rgba(255,255,255,0.4);stroke-width:1")
							.attr("x1", 0)
							.attr("y1", -(iconSize / 2))
							.attr("x2", 0)
							.attr("y2", -(iconSize / 2) - 30);
				
				videolinkwline.attr("style","stroke:rgba(255,255,255,0.4);stroke-width:1")
							.attr("y1", -5)
							.attr("x1", 0)
							.attr("y2", -5)
							.attr("x2", 30);
				
				
				videolinktxt
					.attr("class", "videolinktxt")
					.attr("x", iconSize + 10)
					.attr("fill", "#4B4D54")
					.text("Présentation");
					
					
				videolinkicongrp
					.attr("transform", "translate("+ 0 +","+ -(iconSize/4) +")");
	
					
				videolinkiconcircle
					.attr("r", iconSize)
					.attr("fill", "#ffffff");
	
					
				var	videolinkgrpSize = videolinkgrp.node().getBBox(),
					rectW = videolinkgrpSize.width -40,
					rectH = videolinkgrpSize.height - 30;
	
				videolinkrect
					.attr("height", rectH)
					.attr("width", rectW)
					.attr("x", iconSize + 5)
					.attr("y", -(rectH /1.5))
					.attr("fill", "#ffffff");
					
				videolinkiconicon
					.attr('d', function(d) { var x = 0, y = 0, w = 15, h = 15; return 'M ' + -(w/3) +' '+ -(h/2) + ' l ' + 0 + ' ' + h + ' ' + ' l ' + w + ' ' + -(h/2) + ' z'; })
					.attr("fill", "#4B4D54");
			
				videolinkgrp
					.attr("transform", "translate("+ ((descsize.x - descsize.width)  + videolinkgrpMargin.left) +","+ (descsize.height + videolinkgrpMargin.top) +")");
			} 
			
			// Pour les premiers noeuds
			else {
				
				
				
				// On détermnine si l'on est à au centre, à droite ou à gauche
				
				
				
				// Au centre (le noeud principal
				if(!d.arrowX) {
					
					
					videolinkvline.attr("style","stroke:rgba(255,255,255,0.4);stroke-width:1")
								.attr("x1", 0)
								.attr("y1", -(iconSize / 2))
								.attr("x2", 0)
								.attr("y2", -(iconSize / 2) - 30);
					
					
						
						
					videolinkicongrp
						.attr("transform", "translate("+ 0 +","+ -(iconSize/4) +")");
		
						
					videolinkiconcircle
						.attr("r", iconSize)
						.attr("fill", "#ffffff");
		
						
					var	videolinkgrpSize = videolinkgrp.node().getBBox(),
						rectW = videolinkgrpSize.width -40,
						rectH = videolinkgrpSize.height - 30;
		
						
					videolinkiconicon
						.attr('d', function(d) { var x = 0, y = 0, w = 15, h = 15; return 'M ' + -(w/3) +' '+ -(h/2) + ' l ' + 0 + ' ' + h + ' ' + ' l ' + w + ' ' + -(h/2) + ' z'; })
						.attr("fill", "#4B4D54");
				
					
					videolinkgrp
						.attr("transform", "translate("+ 0 +","+ ((d.img.h/2) + iconSize + 10) +")");
				}
				
				
				// a droite du centre
				else if(d.arrowX < 0) {
					videolinkwline.attr("style","stroke:rgba(255,255,255,0.4);stroke-width:1")
								.attr("y1", -5)
								.attr("x1", 0)
								.attr("y2", -5)
								.attr("x2", 30);
					
					
						
						
					videolinkicongrp
						.attr("transform", "translate("+ 0 +","+ -(iconSize/4) +")");
		
						
					videolinkiconcircle
						.attr("r", iconSize)
						.attr("fill", "#ffffff");
		
						
					var	videolinkgrpSize = videolinkgrp.node().getBBox(),
						rectW = videolinkgrpSize.width -40,
						rectH = videolinkgrpSize.height - 30;
		
						
					videolinkiconicon
						.attr('d', function(d) { var x = 0, y = 0, w = 15, h = 15; return 'M ' + -(w/3) +' '+ -(h/2) + ' l ' + 0 + ' ' + h + ' ' + ' l ' + w + ' ' + -(h/2) + ' z'; })
						.attr("fill", "#4B4D54");
				
					
					videolinkgrp
						.attr("transform", "translate("+ -140 +","+ (iconSize/2) +")");
				} 
				
				// a gauche du centre
				else {
					videolinkwline.attr("style","stroke:rgba(255,255,255,0.4);stroke-width:1")
								.attr("y1", -5)
								.attr("x1", 0)
								.attr("y2", -5)
								.attr("x2", -30);
					
					
						
						
					videolinkicongrp
						.attr("transform", "translate("+ 0 +","+ -(iconSize/4) +")");
		
						
					videolinkiconcircle
						.attr("r", iconSize)
						.attr("fill", "#ffffff");
		
						
					var	videolinkgrpSize = videolinkgrp.node().getBBox(),
						rectW = videolinkgrpSize.width -40,
						rectH = videolinkgrpSize.height - 30;
		
						
					videolinkiconicon
						.attr('d', function(d) { var x = 0, y = 0, w = 15, h = 15; return 'M ' + -(w/3) +' '+ -(h/2) + ' l ' + 0 + ' ' + h + ' ' + ' l ' + w + ' ' + -(h/2) + ' z'; })
						.attr("fill", "#4B4D54");
				
					
					videolinkgrp
						.attr("transform", "translate("+ 140 +","+ (iconSize/2) +")");
				
				}
				
					
					
			}
				
			infogrp
				.on("touchstart", function(){/*d3.event.preventDefault();*/})
				.on("touchmove", function(){/*d3.event.preventDefault();*/})
				.on("click", clickLink);

		}
		
		
	}
	
	
	
	// Les titres statics
	
	function drawStaticTitle(d, node, nbr) {
		d.txttype = "title";
		
		if(d._children || d.link) {
			d.txttype = "link";
		}
		
		var staticTitle = node.append("g")
								.attr("class", function(d){ return "staticTitle " + d.txttype + " " + d.side;});
								
			

								
								
		var	txt_shift = d.side === "left" ? -15 : 15;

		if(d._children || d.link ) {
			
			
			staticTitle.append("circle")
						.attr("r", 10)
						.attr("class", "puce");
			
			if(d.enabled != 0) {
				staticTitle.on("mouseover", function(d){
							_webContainer.selectAll("path.id-"+ d.id).classed("active", true);
							hoverNode(d); 
						})
						.on("mouseout", function(d){
							_webContainer.selectAll("path.id-"+ d.id).classed("active", false);
							hoverOutNode(d);
						});
			}

		}
		
			
		var staticTitleTxt = staticTitle.append("text")
			.attr("x", txt_shift)
			.attr("y", function(d){return d.txttype === "link" ? 5 : 15; })
			.html(function (d){ return d.name});
			
		
		if(d.enabled == 0) {
						
			staticTitle.classed("disabled", true);
			
			staticTitle.on("mousedown", null);	

			var staticTitleSoonTxt = staticTitle.append("text")
				.attr("x", txt_shift)
				.attr("y", function(d){return d.txttype === "link" ? -10 : -20; })
				.text("Bientôt disponible")
				.classed("tooltip", true);

			staticTitle.on("mouseover", function(){
				staticTitle.classed("hover", true);
			}).on("mouseout", function(d){
				staticTitle.classed("hover", false);
			});
			
		} else {
			staticTitle.on("click", click);
		}

	}
	
